import ApiService from './api.service'
import TokenService from './token.service'

class AuthenticationError extends Error {
    constructor(errorCode, message) {
        super(message)
        this.name = this.constructor.name
        this.message = message
        this.errorCode = errorCode
    }
}

function errorCustom(errorCode, message) {
    throw new AuthenticationError(errorCode, message);
}

const UserService = {

    profile: null,

    /**
     * Login the user and store the access token to TokenService. 
     * 
     * @returns auth_token
     * @throws AuthenticationError 
    **/
    login: async function (email, password) {
        const requestData = {
            baseURL: ApiService.getBaseUrl(),
            method: 'post',
            url: "/auth/login",
            data: {
                grant_type: 'password',
                username: email,
                password: password
            },
            auth: {
                username: process.env.VUE_APP_CLIENT_ID,
                password: process.env.VUE_APP_CLIENT_SECRET
            }
        }

        try {
            const response = await ApiService.customRequest(requestData)

            TokenService.saveToken(response.data.auth_token)
            TokenService.saveRefreshToken(response.data.refresh_token)
            ApiService.setAuthHeader()

            // NOTE: We haven't covered this yet in our ApiService 
            //       but don't worry about this just yet - I'll come back to it later
            ApiService.mount401Interceptor();

            return response.data.auth_token
        } catch (error) {
            errorCustom(error.response.status, error.response.data?.detail ?? error.response.data)
        }
    },

    fbLogin: async function () {
        let client_id = '2347690322136378';
        let redirect_uri = '';
        window.open(`https://www.facebook.com/v2.11/dialog/oauth?&response_type=token&display=popup&client_id=${client_id}&display=popup&redirect_uri=${redirect_uri}`, null, 'width=600,height=400');
    },
    facebookLogin: async function (accessToken) {
        const requestData = {
            baseURL: ApiService.getBaseUrl(),
            method: 'post',
            url: "/externalauth/facebook",
            data: {
                accessToken: accessToken
            },
            auth: {
                username: process.env.VUE_APP_CLIENT_ID,
                password: process.env.VUE_APP_CLIENT_SECRET
            }
        }

        try {
            const response = await ApiService.customRequest(requestData);

            TokenService.saveToken(response.data.auth_token)
            TokenService.saveRefreshToken(response.data.refresh_token)
            ApiService.setAuthHeader()

            ApiService.mount401Interceptor();
            return response.data.auth_token
        } catch (error) {
            errorCustom(error.response.status, error.response.data?.detail ?? error.response.data)
        }
    },

    async checkHasGoogleUser(id_token) {
        let response = await ApiService.post('/externalauth/hasGoogleUser', JSON.stringify(id_token) );
        return response.data;
    },

    gLogin: async function () {
        //window.open('https://www.facebook.com/v2.11/dialog/oauth?&response_type=token&display=popup&client_id=1528751870549294&display=popup&redirect_uri=http://localhost:5000/facebook-auth.html&scope=email', null, 'width=600,height=400');
    },
    googleLogin: async function (googleUser) {
        if (googleUser.isSignedIn()) {
            var profile = googleUser.getBasicProfile();
            let accessToken = googleUser.getAuthResponse().id_token;

            //window.console.log('ID: ' + profile.getId()); // Do not send to your backend! Use an ID token instead.
            //window.console.log('Name: ' + profile.getName());
            //window.console.log('Image URL: ' + profile.getImageUrl());
            //window.console.log('Email: ' + profile.getEmail()); // This is null if the 'email' scope is not present.

            const requestData = {
                baseURL: ApiService.getBaseUrl(),
                method: 'post',
                url: "/externalauth/google",
                data: {
                    accessToken: accessToken,
                    firstName: profile.getGivenName(),
                    lastName: profile.getFamilyName(),
                    name: profile.getName(),
                    email: profile.getEmail(),
                    pictureUrl: profile.getImageUrl(),
                    //locale: profile.getLocale(),
                    //gender: profile.getGender()
                },
                auth: {
                    username: process.env.VUE_APP_CLIENT_ID,
                    password: process.env.VUE_APP_CLIENT_SECRET
                }
            }

            try {
                const response = await ApiService.customRequest(requestData);

                TokenService.saveToken(response.data.auth_token)
                TokenService.saveRefreshToken(response.data.refresh_token)
                ApiService.setAuthHeader()

                ApiService.mount401Interceptor();
                return response.data.auth_token
            } catch (error) {
                errorCustom(error.response.status, error.response.data?.detail ?? error.response.data)
            }
        }
    },
    getKeycloakConfigs: async function(){
        try {
            const response = await ApiService.get("/externalauth/GetKeycloakAvailableConfigs");
            return response.data;
        } catch (error) {
            errorCustom(error.response.status, error.response.data?.detail ?? error.response.data)
        }
    },
    keycloakLogin: async function (token) {
        console.info(token);
        const requestData = {
            baseURL: ApiService.getBaseUrl(),
            method: 'post',
            url: "/externalauth/KeycloakLogin?accessToken=".concat(token),
            // data: {
            //     accessToken: token
            // },
            // auth: {
            //     username: process.env.VUE_APP_CLIENT_ID,
            //     password: process.env.VUE_APP_CLIENT_SECRET
            // }
        }
        try {
            const response = await ApiService.customRequest(requestData);

            // TokenService.saveToken(response.data.auth_token)
            // TokenService.saveRefreshToken(response.data.refresh_token)
            // ApiService.setAuthHeader()

            // ApiService.mount401Interceptor();
            return response;
        } catch (error) {
            errorCustom(error.response.status, error.response.data?.detail ?? error.response.data)
        }
    },

    register: async function (email, password, firstName, lastName, location, organizationId) {
        const requestData = {
            baseURL: ApiService.getBaseUrl(),
            method: 'post',
            url: "/account",
            data: {
                email: email,
                password: password,
                firstName: firstName,
                lastName: lastName,
                location: location,
                organizationId: organizationId
            },
            auth: {
                username: process.env.VUE_APP_CLIENT_ID,
                password: process.env.VUE_APP_CLIENT_SECRET
            }
        }

        try {
            const response = await ApiService.customRequest(requestData);
            return response.data;
        } catch (error) {
            errorCustom(error.response.status, error.response.data?.detail ?? error.response.data)
        }
    },

    passwordReset: async function(email) {
        const requestData = {
            baseURL: ApiService.getBaseUrl(),
            method: 'post',
            url: "/passwordrecovery/start",
            data: {
                email: email
            },
            auth: {
                username: process.env.VUE_APP_CLIENT_ID,
                password: process.env.VUE_APP_CLIENT_SECRET
            }
        }

        try {
            await ApiService.customRequest(requestData);
        } catch (error) {
            errorCustom(error.response.status, error.response.data?.detail ?? error.response.data)
        }
        return true;
    },

    savePassword: async function(userId, token, password) {
        const requestData = {
            baseURL: ApiService.getBaseUrl(),
            method: 'post',
            url: "/passwordrecovery/change_password",
            data: {
                userId: userId,
                token: token,
                password: password
            },
            auth: {
                username: process.env.VUE_APP_CLIENT_ID,
                password: process.env.VUE_APP_CLIENT_SECRET
            }
        }
        try {
            return await ApiService.customRequest(requestData);
        } catch (error) {
            errorCustom(error.response.status, error.response.data?.detail ?? error.response.data)
        }
    },

    /**
     * Refresh the access token.
    **/
    refreshToken: async function () {
        const refreshToken = TokenService.getRefreshToken()

        const requestData = {
            baseURL: ApiService.getBaseUrl(),
            method: 'post',
            url: "/auth/token/",
            data: {
                grant_type: 'refresh_token',
                refresh_token: refreshToken
            },
            auth: {
                username: process.env.VUE_APP_CLIENT_ID,
                password: process.env.VUE_APP_CLIENT_SECRET
            }
        }

        try {
            const response = await ApiService.customRequest(requestData)

            TokenService.saveToken(response.data.auth_token)
            TokenService.saveRefreshToken(response.data.refresh_token)
            // Update the header in ApiService
            ApiService.setAuthHeader()

            return response.data.auth_token
        } catch (error) {
            this.logout();
            errorCustom(error.response.status, error.response.data?.detail ?? error.response.data)
        }

    },

    /**
     * Logout the current user by removing the token from storage. 
     * 
     * Will also remove `Authorization Bearer <token>` header from future requests.
    **/
    async logout() {
        // Remove the token and remove Authorization header from Api Service as well 
        TokenService.removeToken()        
        TokenService.removeRefreshToken()
        ApiService.removeHeader('Authorization');
        ApiService.unmount401Interceptor();

        // sign out from Google
        gapi.load('auth2', function () {
            gapi.auth2.init().then(() => {
                if (gapi.auth2) {
                    var auth2 = gapi.auth2.getAuthInstance();
                    if (auth2)
                        auth2.signOut();
                }
            });
        });

        //await gapi.load('auth2');
        //if (gapi.auth2)
        //    await gapi.auth2.init();
        //let auth2 = null;
        //if (gapi.auth2)
        //    auth2 = await gapi.auth2.getAuthInstance();
        //if (auth2)
        //    await auth2.signOut();

    },




    getProfile: async function () {
        const response = await ApiService.get(`/account/profile`);
        return response.data;
    },

    getOrganizations: async function () {
        const response = await ApiService.get(`/organization`);
        return response.data;
    },

    saveOrganizationLink: async function (id) {
        const response = await ApiService.post(`/organization/OrganizationLink/${id}/${localStorage.getItem("isLamoda") == 'true'}`);
        return response.data;
    },
    LamodaAuthentication: async function () {
        window.location = ApiService.getBaseUrl() + "/lamodaAuthentication";
    }
}

const UserLamoda = {
    SignInPortal: async function (user) {
        const response = await ApiService.get(`/setCookies/${user}`);
        var userSignIn = response.data.info;
        if (userSignIn.auth_token) {
            TokenService.saveToken(userSignIn.auth_token);
            TokenService.setIsLamoda(true);
            ApiService.setAuthHeader()
            ApiService.setHeader();
            ApiService.mount401Interceptor();
            return userSignIn.organizationId;
        }
        if (!TokenService.getToken()) return false;
    }
}

export default UserService;


export { UserService, AuthenticationError, UserLamoda };